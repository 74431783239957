
  import { defineComponent } from 'vue';
  import SpeakerButton from '@/components/panel-buttons/SpeakerButton.vue';

  export default defineComponent({
    name: 'SpeakerName',
    components: {
      SpeakerButton,
    },
    props: {
      name: {
        type: String,
        default: '', // Jane Cooper
      },
      isStartSettings: {
        type: Boolean,
        default: false,
      },
      hasVideo: {
        type: Boolean,
        default: false,
      },
      endpointId: {
        type: String,
        default: 'local',
      },
    },
  });
