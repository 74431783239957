
  import { defineComponent, ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { useStore } from 'effector-vue/composition';
  import { reactionsStore, sendReaction } from '@/store/reactions';

  const ANIMATION_TIMEOUT = 600;

  export default defineComponent({
    name: 'EmojiPanel',
    components: {
      Button,
    },
    setup() {
      const animationTimeouts = ref<Record<string, number>>({});
      const reactions = useStore(reactionsStore);

      for (let name in reactions.value.emojilist) {
        animationTimeouts.value[name] = -1;
      }

      const animate = (type: string) => {
        if (animationTimeouts.value[type] !== -1) {
          clearTimeout(animationTimeouts.value[type]);
        }
        animationTimeouts.value[type] = setTimeout(() => {
          animationTimeouts.value[type] = -1;
        }, ANIMATION_TIMEOUT);

        const active = !reactions.value.localReactions[type];
        sendReaction({ type, active });
      };
      return {
        animate,
        animationTimeouts,
        reactions,
      };
    },
  });
