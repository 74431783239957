
  import { computed, defineComponent, onMounted, onBeforeUnmount, ref, Ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { generateCheckClickOutside } from '@voximplant/spaceui';
  import { togglePopup } from '@/store/popup';

  export default defineComponent({
    name: 'PopUpWithButton',
    components: {
      Button,
    },
    props: {
      name: {
        type: String,
        default: 'ic25-settings',
      },
      size: {
        type: String,
        default: '25px',
      },
      isDefaultButton: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const isActive = ref(false);
      const PopUpRef = ref<HTMLElement | null>(null);
      const PopUpButtonRef = ref<HTMLElement | null>(null);
      const checkClickOutside = computed(() =>
        generateCheckClickOutside([PopUpRef, PopUpButtonRef])
      );
      const onClickOutside = (e: MouseEvent) => {
        if (checkClickOutside.value(e)) {
          isActive.value = false;
          togglePopup(isActive.value);
        }
      };

      const toggleClass = () => {
        isActive.value = !isActive.value;
        togglePopup(isActive.value);
      };

      const onKeyDownEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          isActive.value = false;
        }
      };

      onMounted(() => {
        document.addEventListener('click', onClickOutside, { capture: true });
        document.addEventListener('keydown', onKeyDownEsc);
      });
      onBeforeUnmount(() => {
        document.removeEventListener('click', onClickOutside, {
          capture: true,
        });
        document.removeEventListener('keydown', onKeyDownEsc);
      });

      const { buttonMode } = useButtonMode(isActive);
      const { iconProps } = useIconProps(isActive, props.name, props.size);

      return {
        isActive,
        toggleClass,
        PopUpRef,
        PopUpButtonRef,
        buttonMode,
        iconProps,
      };
    },
  });
  function useButtonMode(isActive: Ref<boolean>) {
    const buttonMode = computed(() => (isActive.value ? 'secondary' : 'flat'));
    return { buttonMode };
  }
  function useIconProps(isActive: Ref<boolean>, name: string, size: string) {
    const iconColor = computed(() => (isActive.value ? '--sui-purple-500' : '--sui-gray-700'));
    const iconProps = computed(() => ({
      spriteUrl: '/image/videoconf-icons.svg',
      name: name,
      color: iconColor.value,
      width: size,
      height: size,
    }));

    return { iconProps };
  }
