
  import { defineComponent } from 'vue';
  import Toggle from '@/components/toggles/Toggle.vue';
  import ToggleWithText from '@/components/toggles/ToggleWithText.vue';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';

  export default defineComponent({
    name: 'TogglePanel',
    components: {
      Toggle,
      ToggleWithText,
    },
    props: {
      listToggle: {
        type: Array,
        default: () => [
          'Chat',
          'Video',
          'Share screen',
          'Microphone',
          'Edit username',
          'Private massage',
        ],
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { isMobileScreen } = useIsMobileScreen();
      return {
        isMobileScreen,
      };
    },
  });
