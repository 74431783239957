
  import { computed, defineComponent, ref } from 'vue';
  import { Button, Toggle } from '@voximplant/spaceui';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import { useI18n } from 'vue-i18n';
  import { enableRemoteVideo } from '@/store/endpointMedia';

  export default defineComponent({
    name: 'SpeakerButton',
    components: {
      TitleOption,
      PopUpWithButton,
      Button,
      Toggle,
    },
    props: {
      isAdmin: {
        type: Boolean,
        default: true,
      },
      hasVideo: {
        type: Boolean,
        default: true,
      },
      endpointId: {
        type: String,
        default: 'local',
      },
      listToggles: {
        type: Array,
        default: () => ['Technical Layout', 'Dont’t show this user to other'],
      },
    },
    setup(props) {
      const { t } = useI18n();
      const activeRemoteVideo = ref(true);

      const openFullScreen = () => {
        const videoElement = document.getElementById(props.endpointId);
        if (videoElement) videoElement.requestFullscreen();
      };

      const listMenuDefault = [
        // 'Click user',
        {
          icon: 'ic25-full-screen',
          title: t('speakerMenuList.fullScreen'),
          click: openFullScreen,
        },
      ];

      const listMenu = computed(() => {
        const activeList = [...listMenuDefault];
        if (props.endpointId !== 'local' && props.hasVideo)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          activeList.push({
            icon: 'ic25-video-off',
            title: t('speakerMenuList.toggleRemoteVideoOff'),
            click: toggleRemoteVideo,
          });

        return activeList;
      });

      const toggleRemoteVideo = () => {
        activeRemoteVideo.value = !activeRemoteVideo.value;
        let menu = listMenu.value.find((item) => item.icon.includes('ic25-video'));
        if (menu) {
          let toggle = activeRemoteVideo.value ? 'Off' : 'On';
          menu.icon = 'ic25-video-' + toggle.toLowerCase();
          menu.title = t(`speakerMenuList.toggleRemoteVideo${toggle}`);
        }
        enableRemoteVideo({ endpointId: props.endpointId, active: activeRemoteVideo.value });
      };

      const listAllowUsers = [
        t('menuList.chat'),
        t('menuList.privateMessages'),
        t('menuList.rename'),
      ];

      return {
        t,
        listMenu,
        listAllowUsers,
        openFullScreen,
      };
    },
  });
