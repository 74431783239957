
  import { defineComponent, ref, watch } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { isPopupOpened } from '@/store/popup';
  import { useStore } from 'effector-vue/composition';

  export default defineComponent({
    name: 'Tooltip',
    components: {
      Button,
    },
    props: {
      text: {
        type: String,
      },
    },
    setup() {
      const openedPopup = useStore(isPopupOpened);
      const justClosedPopup = ref(false);
      watch(openedPopup, (opened) => {
        if (!opened) {
          if (isMouseOver.value) {
            justClosedPopup.value = true;
          }
        }
      });
      const isMouseOver = ref(false);
      const onMouseLeave = () => {
        isMouseOver.value = false;
        justClosedPopup.value = false;
      };
      return {
        openedPopup,
        isMouseOver,
        onMouseLeave,
        justClosedPopup,
      };
    },
  });
