
  import { computed, defineComponent, ref } from 'vue';
  import { Input, Select, Button } from '@voximplant/spaceui';
  import DownloadedFiles from '@/components/decorative-elements/DownloadedFiles.vue';
  import FileInput from '@/components/inputs/FileInput.vue';
  import ToggleWithText from '@/components/toggles/ToggleWithText.vue';
  import Sound from '@/components/decorative-elements/Sound.vue';
  import { openDrawer, closeDrawer } from '@/store/drawer';
  import SignInAccount from '@/components/accounts/SignInAccount.vue';
  import { useStore } from 'effector-vue/composition.cjs';
  import { $devices, setVideoQuality } from '@/store/devices/index';
  import { requestMirrorStream } from '@/store/mirrorMedia/index';
  import { authStore } from '@/store/auth';
  import { useI18n } from 'vue-i18n';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'MobileExpandedSettings',
    components: {
      Input,
      Select,
      Button,
      DownloadedFiles,
      FileInput,
      Sound,
      SignInAccount,
      ToggleWithText,
    },
    props: {
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const auth = useStore(authStore);
      const updateVideoDevice = (e: VideoDeviceInfo) => {
        requestMirrorStream({
          selectedAudioDevice: $devices.getState().selectedAudioDevice,
          selectedVideoDevice: e,
        });
      };
      const updateVideoQuality = (e: Record<string, string>) => {
        setVideoQuality(e);
      };
      const updateAudioDevice = (e: AudioDeviceInfo) => {
        requestMirrorStream({
          selectedAudioDevice: e,
          selectedVideoDevice: $devices.getState().selectedVideoDevice,
        });
      };
      const isAudioDisabled = computed(
        () => !!props.deviceError && props.deviceError !== 'noCamera'
      );

      return {
        openDrawer,
        updateAudioDevice,
        auth,
        devices,
        closeDrawer,
        updateVideoDevice,
        updateVideoQuality,
        isAudioDisabled,
        t,
      };
    },
  });
