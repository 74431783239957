
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SignInAccount',
    components: {},
    // isProfile - use to change info
    props: {
      avatar: {
        type: String,
        default: require('@/assets/images/mocks/icon-A.svg'),
      },
      userName: {
        type: String,
        required: true,
      },
      email: {
        type: String,
        required: true,
      },
      isProfile: {
        type: Boolean,
        default: false,
      },
    },
  });
