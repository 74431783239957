
  import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
  import { RadioButton } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';

  export default defineComponent({
    components: {
      RadioButton,
    },
    emits: ['active'],
    props: {
      name: {
        type: String,
      },
      type: {
        type: String,
      },
      description: {
        type: String,
      },
      layoutScreen: {
        type: String,
      },
      active: {
        type: String,
      },
    },

    setup(props, { emit }) {
      let windowWidth = ref(window.innerWidth);
      const onWidthChange = () => (windowWidth.value = window.innerWidth);
      onMounted(() => window.addEventListener('resize', onWidthChange));
      onUnmounted(() => window.removeEventListener('resize', onWidthChange));
      const { isMobileScreen } = useIsMobileScreen();

      const onRadioSelect = () => {
        emit('active', props.name);
      };

      return { onRadioSelect, isMobileScreen };
    },
  });
